.user-info {
  color: white;
  font-weight: bold;
  text-align: center;
  text-shadow: 0px 0px 1vh black;
  margin-bottom: 2vh;

  .name {
    margin-bottom: 1vh;
  }

  .elo {
    font-size: 2em;
  }
}
