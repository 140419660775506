$hole-bisel-dark: rgba(
  $color: #000,
  $alpha: 0.2,
);
$hole-bisel-light: rgba(
  $color: #fff,
  $alpha: 0.4,
);
$hole-bisel-depth: 2vw;
$hole-bisel-size: 30%;

$board-base-color: #a7936c;
$board-p1-color: #0026fe57;
$board-p2-color: #ff0000b1;
