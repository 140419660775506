.input {
  height: 10vh;
  width: 80vw;
  margin-bottom: 2vh;
  padding: 1vh;
  text-align: center;
  font-size: 3vh;
  color: black;
  font-weight: bold;
  
  &:last-child {
    margin-bottom: 0;
  }
}