@import '../vars.scss';

.board {
  background-color: $board-base-color;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  position: absolute;
  top: 0;
  background-position: center center;
  background-size: cover;

  &.reverse {
    flex-direction: column-reverse;

    .row {
      flex-direction: row-reverse;
    }
  }

  .row {
    display: flex;
    flex-direction: row;
    flex: 1;

    &:first-child {
      background-color: $board-p1-color;
    }

    &:last-child {
      background-color: $board-p2-color;
    }

    .cell {
      flex: 1;

      &::after {
        content: "";
        width: 50%;
        height: 50%;
        display: block;
        border-radius: 50%;
        transform: translateX(-50%) translateY(-50%);
        margin-left: 50%;
        margin-top: 50%;
        background-color: transparent;
        background-image: url("../images/hole.png");
        background-size: contain;
        background-origin: border-box;
        border: 0;
        background-repeat: no-repeat;
        background-position: center center;
      }

      &.destination::after {
        border: 2px solid white;
      }
    }
  }
}
