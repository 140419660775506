.message {
  position: absolute;
  width: 95vw;
  height: 100vh;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  top: 0;
  left: 2.5vw;

  .message-inner {
    background-color: #f5deb3b8;
    padding: 6vw;
    border: 3px solid #7f622d;
    font-size: 3vh;
    font-weight: bold;

    .button-container {
      margin-top: 6vw;

      .button {
        display: inline-flex;
        margin-bottom: 0;
      }
    }
  }
}
