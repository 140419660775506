@import "vars.scss";

.app {
  height: 100vh;
  background-size: cover;
  background-position: center center;

  font-size: 3vh;
}

.game {
  height: 100vh;
}

.player-details {
  position: absolute;
  bottom: calc(50vh + 50vw);
  color: white;
  text-shadow: 0 0px 5px black;
  font-weight: bold;
  width: 95vw;
  left: 2.5vw;
  display: flex;
  justify-content: space-between;
  font-size: 5vh;
  background: #ff000080;
  padding: 2vw;
  box-sizing: border-box;
  align-items: flex-end;

  &.reversed {
    background: #0000ff80;

    &:last-child {
      background: #ff000080;
    }
  }

  .name {
    display: flex;
    flex-direction: column;
  }

  @media (min-aspect-ratio: 8/16) {
    bottom: 77vh;
  }

  @media (min-aspect-ratio: 10/16) {
    bottom: 82vh;
  }

  &:last-child {
    top: calc(50vh + 50vw);
    bottom: initial;
    background: #0000ff80;
    align-items: flex-start;

    @media (min-aspect-ratio: 8/16) {
      top: 77vh;
    }
  
    @media (min-aspect-ratio: 10/16) {
      top: 82vh;
    }
  }

  div:first-child {
    overflow: hidden;
  }
}

.board-container {
  position: absolute;
  height: 95vw;
  width: 95vw;
  transform: translateY(-50%) translateX(-50%);
  top: 50%;
  left: 50%;
  
  @media (min-aspect-ratio: 8/16) {
    height: 50vh;
    width: 50vh;
  }

  @media (min-aspect-ratio: 10/16) {
    height: 60vh;
    width: 60vh;
  }
}

.games {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
  overflow-x: hidden;
  overflow-y: auto;

  tr {
    cursor: pointer;

    td {
      vertical-align: middle;
      color: white;
      font-weight: bold;
      overflow: hidden;
      padding: 1vh 0.5vh;
    }

    .result-w,
    .result-l {
      width: 2vh;
      height: 2vh;
    }

    .result-w {
      background-color: greenyellow;
    }

    .result-l {
      background-color: red;
    }

    .my-name,
    .opponent-name {
      
    }
  }
}