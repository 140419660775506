.icon-button {
  height: 10vh;
  width: 10vh;
  margin-bottom: 2vh;
  display: inline-flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  font-size: 3vh;
  color: black;
  text-decoration: none;
  background: transparent;
  border: 0;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  background-image: url(../images/button-squared.png);
  text-shadow: 1px 1px 0 #f58420, -1px 1px 0 #933701, -1px -1px 0 #933701, 1px -1px 0 #f58420;
  font-weight: bold;
  line-height: 2.4vh;
  cursor: pointer;

  &.disabled {
    cursor: default;
  }

  &:last-child {
    margin-bottom: 0;
  }
}