@import "../vars.scss";

.piece {
  position: absolute;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  border-width: 2vw;
  border-color: $hole-bisel-light $hole-bisel-dark $hole-bisel-dark
    $hole-bisel-light;
  border-style: solid;
  box-sizing: border-box;
  transition: left 0.2s ease-out, top 0.2s ease-out;
  background-color: transparent;
  background-size: contain;
  background-origin: border-box;
  border: 0;
  box-shadow: 2vw 2vw 6vw 0px black;

  &.p1 {
    background-image: url("../images/red-piece.png");
  }

  &.p2 {
    background-image: url("../images/blue-piece.png");
  }

  &.p0 {
    background-image: url("../images/neutron.png");
  }

  &.selected {
    border: 2px solid white;
  }
}
